import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import clevertap from 'clevertap-web-sdk';
import { DATE_BE, DATE_TIME_BE } from 'constant';
import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';
import {
  DataResponse,
  ListAxiosResponse,
  MessageAxiosResponse,
  ObjectAxiosResponse,
  Order,
  Payload,
  PrimitiveResponse,
  Query,
  ShallowNonNullish,
} from 'types';
import { mapSorterToParams, parseToFormData, removeNullish } from 'utils';

import { IApplicant, IJobApplicant, JobApplicantStatusText } from 'models/applicant';
import { BadgesOption } from 'models/badges';
import {
  IJob,
  IJobSlot,
  IJobSlotUserFeedback,
  IJobSlotUserHistory,
  IJobTemplate,
  ISlotUser,
  JobStatus,
  JobTemplateOption,
  WorkerStatus,
} from 'models/job';
import { LocationOption, LocationOptionResponse } from 'models/location';
import { Role } from 'models/user';

import { JobHistoryFilterData } from 'components/molecules/JobHistoryFilter';
import { JobManageFilterData } from 'components/molecules/JobManageFilter';
import { JobPostingFilterData } from 'components/molecules/JobPostingFilter';
import { SlotUserFilterData } from 'components/molecules/SlotUserFilter';
import { ApplicantSlotAdjustmentFormData } from 'components/organisms/ApplicantSlotAdjustmentForm';
import { JobFormData } from 'components/organisms/JobForm';
import { SlotUserFormData } from 'components/organisms/SlotUserForm';
import { Sorter } from 'components/organisms/Table';

import { orderOptions } from 'utils/array';

import {
  getPathByRole,
  mapErrorDescriptions,
  mapPaginationParams,
  mapPaginationState,
  mapQueryToParams,
} from './utils';

const entity = 'jodJob';
const basePath = `portal/${entity}` as const;
const jodJobUrl = `portal/jod_jobs` as const;

const jobPayloadScheme: Record<string, keyof JobFormData> = {
  job_start_date: 'dateRange',
  job_end_date: 'dateRange',
  date_of_week: 'requiredDays',
  location_id: 'location',
  job_template_id: 'template',
  job_type_id: 'jobType',
  job_title: 'title',
  job_description: 'description',
  special_instructions: 'specialInstructions',
  hourly_rate: 'wage',
  bank_transfer: 'bankTransfer',
  food_hygiene_certificate_required: 'foodHygieneRequired',
  user_location_id: 'manager',
};

const slotUserPayloadScheme: Record<string, keyof SlotUserFormData> = {
  job_id: 'jobId',
  app_user_id: 'userId',
  slot_id: 'slotId',
  has_feedback: 'hasFeedback',
  manager_feedback_slot: 'managerFeedback',
  manager_rating_slot: 'managerRating',
  applicant_rating_slot: 'applicantRating',
  applicant_feedback_slot: 'applicantFeedback',
  ack2: 'ack2',
  ack2_datetime: 'ack2Datetime',
  jod_ack2_reminder: 'jodAck2Reminder',
  clock_in: 'clockIn',
  clock_out: 'clockOut',
  clock_in_status: 'clockInStatus',
  clock_out_status: 'clockOutStatus',
  actual_clock_in: 'actualClockIn',
  actual_clock_out: 'actualClockOut',
  manager_clock_in: 'managerClockIn',
  manager_clock_out: 'managerClockOut',
  break_time: 'breakTime',
  admin_clock_in: 'adminClockIn',
  admin_clock_out: 'adminClockOut',
  admin_break_time: 'adminBreakTime',
  sponsored_break_time: 'sponsoredBreakTime',
  admin_hourly_rate: 'adminHourlyRate',
  admin_comment: 'adminComment',
  admin_jod_allowance: 'adminJodAllowance',
  status: 'status',
  deleted_at: 'deletedAt',
  admin_jod_allowance_deduction: 'adminJodAllowanceDeduction',
};

export const jobApi = {
  async getTemplateOptions(): Promise<DataResponse<JobTemplateOption[]>> {
    try {
      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'listOptionJobTemplateForHQUser',
        [Role.AreaManager]: 'listOptionJobTemplateForAreaLocationUser',
        [Role.LocationManager]: 'listOptionJobTemplateForAreaLocationUser',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res: AxiosResponse<{ data: IJobTemplate[] }> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
      );
      return {
        data: orderOptions(
          res.data.data.map((item) => ({ ...item, value: item.id, label: item.name })),
        ),
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getLocationOptions(
    role: Role.AreaManager | Role.HqManager,
  ): Promise<DataResponse<LocationOption[]>> {
    const authorizedPath = getPathByRole({
      [Role.HqManager]: 'listOptionLocationForHQUser',
      [Role.AreaManager]: 'listOptionLocationForAreaUser',
    });
    if (!authorizedPath) {
      throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
    }
    try {
      const res: AxiosResponse<{ data: LocationOptionResponse[] }> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
      );
      return {
        data: orderOptions(
          res.data.data.map((item) => {
            const { area_user, location_users } = item;
            const locationManager: LocationOption['location_users'] = orderOptions(
              location_users?.map((item) => ({
                label: item.full_name,
                value: item.id,
              })) ?? [],
            );
            const areaManager: LocationOption['area_user'] = area_user && {
              label: area_user.full_name,
              value: area_user.id,
            };
            return {
              ...item,
              value: item.id,
              label: item.name,
              location_users: locationManager ?? [],
              area_user: areaManager,
            };
          }),
        ),
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async createByHQUser(data: ShallowNonNullish<JobFormData>): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        location_id: data.location.value,
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        bank_transfer: 1,
        cash: 0,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        job_template_id: data.template?.value, // optional
        user_location_id: data.manager?.value, // optional
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/storeJodJobForHQUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      // clevertap.event.push("Job Post Complete", {
      //   // "Job ID": ,
      //   "Job Title": body.job_title,
      //   // "Job Location Address": body,
      //   "Job Type": body.job_type_id,
      //   // "Job Industry": body.,
      //   "Job Description": body.job_description,
      //   // "Company Name": body.,
      //   "Outlet Name": body.location_id,
      //   "Special Instruction": body.special_instructions,
      //   "Job Start Date Time": body.job_start_date,
      //   "Job End Date Time": body.job_end_date,
      //   "Hourly Rate": body.hourly_rate,
      //   // "Total Job Salary":
      //   // "Total Working Hours": body.hor,
      //   // "Total Working Minutes":
      // });
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async createByAreaUser(data: ShallowNonNullish<JobFormData>): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        location_id: data.location.value,
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        bank_transfer: 1,
        cash: 0,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        job_template_id: data.template?.value, // optional
        user_location_id: data.manager?.value, // optional
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/storeJodJobForAreaUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      // clevertap.event.push("Job Post Complete", {
      //   // "Job ID": ,
      //   "Job Title": body.job_title,
      //   // "Job Location Address": body.locati,
      //   "Job Type": body.job_type_id,
      //   // "Job Industry": body.,
      //   "Job Description": body.job_description,
      //   // "Company Name": body.,
      //   "Outlet Name": body.location_id,
      //   "Special Instruction": body.special_instructions,
      //   "Job Start Date Time": body.job_start_date,
      //   "Job End Date Time": body.job_end_date,
      //   "Hourly Rate": body.hourly_rate,
      //   // "Total Job Salary":
      //   // "Total Working Hours": body.hor,
      //   // "Total Working Minutes":
      // });
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async updateByAreaUser(
    id: number,
    data: ShallowNonNullish<JobFormData>,
  ): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_id: id,
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        location_id: data.location.value,
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        bank_transfer: 1,
        cash: 0,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        job_template_id: data.template?.value, // optional
        user_location_id: data.manager?.value, // optional
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/updateJodJobForAreaUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updatedNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async createByLocationUser(data: ShallowNonNullish<JobFormData>): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        cash: 0,
        bank_transfer: 1,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        job_template_id: data.template?.value, // optional
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/storeJodJobForLocationUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async updateByLocationUser(
    id: number,
    data: ShallowNonNullish<JobFormData>,
  ): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_id: id,
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        cash: 0,
        bank_transfer: 1,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        job_template_id: data.template?.value, // optional
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/updateJodJobForLocationUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updatedNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async updateByHqUser(
    id: number,
    data: ShallowNonNullish<JobFormData>,
  ): Promise<DataResponse<null>> {
    try {
      const [start, end] = data.dateRange;
      const body = parseToFormData({
        job_id: id,
        job_start_date: start.set('second', 0).format(DATE_TIME_BE),
        job_end_date: end.set('second', 0).format(DATE_TIME_BE),
        date_of_week: Object.entries(data.requiredDays)
          .filter((item) => item[1])
          .map((item) => item[0])
          .join(),
        job_type_id: data.jobType.value,
        job_title: data.title,
        job_description: data.description,
        special_instructions: data.specialInstructions,
        hourly_rate: data.wage,
        cash: 0,
        bank_transfer: 1,
        cheque: 0,
        food_hygiene_certificate_required: data.foodHygieneRequired ? 1 : 0,
        location_id: data.location.id,
        job_template_id: data.template?.value, // optional
        user_location_id: data.manager?.value, // optional
        status: data.status,
        candidate_instructions_file: data.candidateInstructionsFile,
        required_certificates: JSON.stringify(data.requiredCertificates)
      });
      const res: AxiosResponse<{ message: string }> = await httpClient.post(
        `${basePath}/updateJodJobForHQUser`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updatedNewJobUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(jobPayloadScheme, description),
      });
    }
  },
  async getJobHistoryByLocationId(
    payload: Payload<null, { id: string | number }, Query>,
  ): Promise<DataResponse<IJobSlotUserHistory[]>> {
    try {
      const { pagination, query, params } = payload;
      const { sort } = query || {};
      const {
        data: { data, message },
      }: ListAxiosResponse<IJobSlotUserHistory> = await httpClient.get(
        `${basePath}/list-job-slot-history-with-location-id`,
        {
          params: mapQueryToParams({ sort }, pagination, {
            location_id: params.id,
          }),
        },
      );

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobUserFeedbackDetail(payload: {
    jobId: string | number;
    applicantId: string | number;
  }): Promise<DataResponse<IJobSlotUserFeedback>> {
    const { jobId, applicantId } = payload;
    try {
      const res: ObjectAxiosResponse<IJobSlotUserFeedback> = await httpClient.get(
        `${basePath}/feed-back-of-job`,
        {
          params: {
            job_id: jobId,
            applicant_id: applicantId,
          },
        },
      );

      const { data } = res;
      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobHistoryList(
    payload: Payload<null, null, Query & Partial<JobHistoryFilterData>>,
  ): Promise<DataResponse<IJobSlotUserHistory[]>> {
    try {
      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'list-job-history-by-hq-manager',
        [Role.AreaManager]: 'list-job-history',
        [Role.LocationManager]: 'list-job-history-by-location-manager',
      });
      if (!authorizedPath) {
        throw new Error('You have no permission to access this feature');
      }
      const { pagination, query } = payload;
      const { search, sort, minCredit, maxCredit, dateRange } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<IJobSlotUserHistory> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            minimum_total_credit: minCredit,
            maximum_total_credit: maxCredit,
            clock_in: startDate ? dayjs(startDate).set('second', 0).format(DATE_TIME_BE) : null,
            clock_out: endDate ? dayjs(endDate).set('second', 0).format(DATE_TIME_BE) : null,
          }),
        },
      );

      const { data } = res;

      return {
        status: true,
        data: data.data.data,
        pagination: mapPaginationState(data.data),
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobHistorySummary(): Promise<DataResponse<{ number_total: number }>> {
    try {
      const authorizedPath = getPathByRole({
        [Role.AreaManager]: 'job-history-number-total',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res: ObjectAxiosResponse<{ number_total: number }> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
      );
      return {
        status: true,
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobPostingList(
    payload: Payload<
      null,
      null,
      {
        search?: string | null;
        sort?: Sorter | null;
        locationId?: string | null;
      } & Partial<JobPostingFilterData>
    >,
  ): Promise<DataResponse<IJob[]>> {
    try {
      const { pagination, query } = payload;
      const {
        locationId,
        maxWage,
        minWage,
        search,
        dateRange,
        sort,
        jobStatus,
        jobWithApplicants,
      } = query || {};
      const [startDate, endDate] = dateRange || [];
      const {
        data: { data, message },
      }: ListAxiosResponse<IJob> = await httpClient.get(`${basePath}/indexJobPosting`, {
        params: mapQueryToParams(
          {
            sort: sort ?? { key: 'job_start_date', order: Order.Descend, field: 'job_start_date' },
            search,
          },
          pagination,
          {
            filter_types: jobStatus?.value,
            job_with_applicants: jobWithApplicants ? 1 : null,
            location_id: locationId,
            max_wage: maxWage,
            min_wage: minWage,
            job_start_date: startDate ? dayjs(startDate).format(DATE_BE) : null,
            job_end_date: startDate ? dayjs(endDate).format(DATE_BE) : null,
          },
          // { sortDefaultByCreatedAt: true },
        ),
      });

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobSummary(): Promise<DataResponse<{ totalJobs: number }>> {
    try {
      // TODO: change real api
      const {
        data: { data, message },
      }: ListAxiosResponse<IJob> = await httpClient.get(`${basePath}/indexJobPosting`, {
        params: {
          limit: 1,
          page: 1,
        },
      });

      return {
        status: true,
        data: { totalJobs: data.total },
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportJobHistoryDetailBySlotId(
    payload: Payload<null, { slot_id: number; applicant_id: number }>,
  ): Promise<DataResponse<IJobSlotUserHistory>> {
    try {
      const { params } = payload;

      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'download-excel-slot-detail-history-by-hq',
        [Role.AreaManager]: 'download-excel-slot-detail-history',
        [Role.LocationManager]: 'download-excel-slot-detail-history',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }

      const res = await httpClient.get(`${basePath}/${authorizedPath}`, {
        params: {
          slot_id: params.slot_id,
          user_id: params.applicant_id,
        },
        responseType: 'arraybuffer',
      });
      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async exportLocationJobHistory(
    payload: Payload<null, null, Pick<Query, 'search'> & Partial<JobHistoryFilterData>>,
  ): Promise<DataResponse<IJobSlotUserHistory[]>> {
    try {
      const { query } = payload;
      const { search, minCredit, maxCredit, dateRange } = query || {};
      const [startDate, endDate] = dateRange || [];

      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'download-excel-full-job-history-by-hq-manager',
        [Role.AreaManager]: 'download-excel-full-job-history',
        [Role.LocationManager]: 'download-excel-full-job-history-by-location-manager',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res = await httpClient.get(`${basePath}/${authorizedPath}`, {
        params: mapQueryToParams({ search }, undefined, {
          minimum_total_credit: minCredit,
          maximum_total_credit: maxCredit,
          clock_in: startDate ? dayjs(startDate).set('second', 0).format(DATE_TIME_BE) : null,
          clock_out: startDate ? dayjs(endDate).set('second', 59).format(DATE_TIME_BE) : null,
        }),
        responseType: 'arraybuffer',
      });

      return {
        status: true,
        data: res.data,
        message: i18next.t<string>('message.exportSuccess'),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getDetail(id: number | string): Promise<DataResponse<IJob>> {
    try {
      const res: ObjectAxiosResponse<IJob> = await httpClient.get(`${basePath}/jobDetail`, {
        params: {
          job_id: id,
        },
      });
      return {
        status: true,
        message: '',
        data: res.data.data,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async getAppliedApplicants(
    payload: Payload<null, { jobId: string | number }, { sort?: Sorter | null }>,
  ): Promise<DataResponse<IApplicant[]>> {
    try {
      const { pagination, query, params } = payload;

      const sort = query?.sort;
      const res: ListAxiosResponse<IApplicant> = await httpClient.get(
        `${basePath}/indexApplicantApplyJobByJobId`,
        {
          params: {
            ...mapPaginationParams(pagination),
            ...(sort ? mapSorterToParams(sort) : {}),
            job_id: params.jobId,
          },
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async approveApplicants(
    jobId: string | number,
    applicantIds: string[],
  ): Promise<DataResponse<IJob>> {
    try {
      const approveRes: MessageAxiosResponse = await httpClient.post(
        `${basePath}/approveApplicantSelectForJob`,
        {
          job_id: jobId,
          app_user_ids: applicantIds.join(),
        },
      );
      const {
        data: { message },
      } = approveRes;

      const jobDetailRes = await this.getDetail(jobId);
      // clevertap.event.push("Selected Workers", {
      //   "Job ID": jobDetailRes.data.id,
      //   "Job Title": jobDetailRes.data.job_title,
      //   "Job Location Address": jobDetailRes.data.location.name,
      //   "Job Type": jobDetailRes.data.job_type,
      //   // "Job Industry": jobDetailRes.data.,
      //   "Job Description": jobDetailRes.data.job_description,
      //   // "Company Name": jobDetailRes.data.,
      //   "Outlet Name": jobDetailRes.data.location_id,
      //   "Special Instruction": jobDetailRes.data.special_instructions,
      //   "Job Start Date Time": jobDetailRes.data.job_start_date,
      //   "Job End Date Time": jobDetailRes.data.job_end_date,
      //   "Hourly Rate": jobDetailRes.data.hourly_rate,
      //   // "Total Job Salary":
      //   // "Total Working Hours": body.hor,
      //   // "Total Working Minutes":
      // });
      return {
        status: true,
        data: jobDetailRes.data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getJobSlots(
    payload: Payload<null, { jobId: string | number }, { sort?: Sorter | null }>,
  ): Promise<DataResponse<IJobSlot[]>> {
    try {
      const { pagination, query, params } = payload;

      const sort = query?.sort;
      const res: ListAxiosResponse<IJobSlot> = await httpClient.get(
        `${basePath}/indexSlotsForJob`,
        {
          params: {
            ...mapPaginationParams(pagination),
            ...(sort ? mapSorterToParams(sort) : {}),
            job_id: params.jobId,
          },
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async deleteJob(jobId: string | number, isReject?: boolean): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.delete(`${jodJobUrl}/${jobId}`);
      const {
        data: { message },
      } = res;

      return {
        status: true,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.deletedJobFailed'),
      });
    }
  },
  async cancelJob(
    jobId: string | number,
    cancellationReasonId: number,
    isReject?: boolean,
  ): Promise<DataResponse<IJob>> {
    try {
      const cancelRes: MessageAxiosResponse = await httpClient.put(`${basePath}/cancelJodJob`, {
        job_id: jobId,
        cancellation_reason_id: cancellationReasonId,
      });
      const {
        data: { message },
      } = cancelRes;

      const jobDetailRes = await this.getDetail(jobId);

      return {
        status: true,
        data: jobDetailRes.data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.deletedJobFailed'),
      });
    }
  },
  async deleteMultipleJob(jobIds: string[]): Promise<DataResponse<null>> {
    try {
      const deleteRes: MessageAxiosResponse = await httpClient.put(
        `${basePath}/deleteMultipleJodJob`,
        {
          job_ids: jobIds.join(),
        },
      );
      const {
        data: { message },
      } = deleteRes;

      return {
        status: true,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.deletedJobFailed'),
      });
    }
  },
  async getClockInQrCode(
    payload: Payload<null, { slotId: number; applicantId: number }>,
  ): Promise<DataResponse<{ qrCode: string }>> {
    try {
      const { slotId, applicantId } = payload.params;
      const res: AxiosResponse = await httpClient.get(`${basePath}/generateClockInQrCodeSlot`, {
        params: {
          slot_id: slotId,
          app_user_id: applicantId,
        },
      });

      return {
        data: { qrCode: res.data },
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getClockOutQrCode(
    payload: Payload<null, { slotId: number; applicantId: number }>,
  ): Promise<DataResponse<{ qrCode: string }>> {
    try {
      const { slotId, applicantId } = payload.params;
      const res: AxiosResponse = await httpClient.get(`${basePath}/generateClockOutQrCodeSlot`, {
        params: {
          slot_id: slotId,
          app_user_id: applicantId,
        },
      });

      return {
        data: { qrCode: res.data },
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async adjustmentSlotUser(
    slotId: number,
    applicantId: number,
    adjustment: ShallowNonNullish<ApplicantSlotAdjustmentFormData>,
  ): Promise<DataResponse<{ qrCode: string }>> {
    const adjustmentPayloadScheme: Record<string, keyof ApplicantSlotAdjustmentFormData> = {
      clock_in_time: 'clockIn',
      clock_out_time: 'clockOut',
      break_time: 'breakTime',
      jod_badge: 'jodBadge',
      manager_rating_job: 'rate',
      manager_feedback_job: 'feedback',
    };
    try {
      const { clockOut, clockIn, breakTime, rate, jodBadge, feedback } = adjustment;
      await httpClient.post(
        `${basePath}/confirmAdjustmentSlotUser`,
        removeNullish({
          slot_id: slotId,
          app_user_id: applicantId,
          clock_in_time: clockIn.set('second', 0).format(DATE_TIME_BE),
          clock_out_time: clockOut.set('second', 0).format(DATE_TIME_BE),
          break_time: breakTime.hour() * 60 + breakTime.minute(),
          manager_rating_job: rate,
          jod_badge: jodBadge,
          manager_feedback_job: feedback || undefined,
        }),
      );

      const res = await this.getClockOutQrCode({ params: { slotId, applicantId } });

      return {
        data: { qrCode: res.data.qrCode },
        message: '',
        status: true,
      };
    } catch (error: any) {
      const { message, description } = error;
      return Promise.reject({
        status: false,
        message: message,
        description: mapErrorDescriptions(adjustmentPayloadScheme, description),
      });
    }
  },
  async adjustSlotUserStatus(
    slotId: number,
    applicantId: number,
    adjustment: ApplicantSlotAdjustmentFormData,
  ): Promise<DataResponse<any>> {
    const adjustmentPayloadScheme: Record<string, keyof ApplicantSlotAdjustmentFormData> = {
      clock_in_time: 'clockIn',
      clock_out_time: 'clockOut',
      break_time: 'breakTime',
      manager_rating_job: 'rate',
      manager_feedback_job: 'feedback',
      jod_badge: 'jodBadge',
      status: 'status',
      reason_attachment: 'reasonAttachment',
      update_reason: 'updateReason',
    };
    try {
      const {
        clockOut,
        clockIn,
        breakTime,
        rate,
        feedback,
        jodBadge,
        status,
        reasonAttachment,
        updateReason,
      } = adjustment;
      const formData = parseToFormData({
        slot_id: slotId,
        app_user_id: applicantId,
        clock_in_time: clockIn.set('second', 0).format(DATE_TIME_BE),
        clock_out_time: clockOut.set('second', 0).format(DATE_TIME_BE),
        break_time: breakTime.hour() * 60 + breakTime.minute(),
        manager_rating_job: rate,
        jod_badge: jodBadge,
        manager_feedback_job: feedback || undefined,
        status: status ? parseInt(status.toString()) : undefined,
        reason_attachment: reasonAttachment,
        update_reason: updateReason ? updateReason : '',
      });
      const res = await httpClient.post(`${basePath}/slotUserStatusUpdate`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return {
        data: res.data.data,
        message: res.data.message,
        status: res.data.status,
      };
    } catch (error: any) {
      const { message, description } = error;
      return Promise.reject({
        status: false,
        message: message,
        description: mapErrorDescriptions(adjustmentPayloadScheme, description),
      });
    }
  },
  async getJobApplicants(
    payload: Payload<
      null,
      null,
      Query & {
        locationId?: number;
        status?: JobApplicantStatusText;
        jobStatus?: JobStatus;
        applyDate?: Dayjs;
        startDate?: Dayjs;
        endDate?: Dayjs;
        jobId?: number | string;
        applicantId?: number | string;
      }
    >,
  ): Promise<DataResponse<IJobApplicant[]>> {
    try {
      const { pagination, query, cancelToken } = payload;
      const {
        sort,
        search,
        locationId,
        status,
        applyDate,
        startDate,
        endDate,
        jobId,
        applicantId,
        jobStatus,
      } = query || {};
      const res: ListAxiosResponse<IJobApplicant> = await httpClient.get(
        `${basePath}/indexApplicantsByJob`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              location_id: locationId,
              filter_types: jobStatus && status ? `${jobStatus},${status}` : jobStatus ?? status,
              apply_date: applyDate?.format(DATE_BE),
              job_start_date: startDate?.set('second', 0).format(DATE_TIME_BE),
              job_end_date: endDate?.set('second', 59).format(DATE_TIME_BE),
              job_id: jobId,
              app_user_id: applicantId,
            },
            {
              sortDefault: {
                key: 'apply_date',
                order: Order.Descend,
                field: 'apply_date',
              },
            },
          ),
          cancelToken,
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async approveJob(jobId: string | number): Promise<DataResponse<IJob>> {
    try {
      const approveRes: MessageAxiosResponse = await httpClient.put(
        `${basePath}/approveJobForHqUser`,
        {
          job_id: jobId,
        },
      );
      const {
        data: { message },
      } = approveRes;

      const jobDetailRes = await this.getDetail(jobId);
      // clevertap.event.push("Job Post Approval Complete", {
      //   "Job ID": jobDetailRes.data.id,
      //   "Job Title": jobDetailRes.data.job_title,
      //   "Job Location Address": jobDetailRes.data.location.name,
      //   "Job Type": jobDetailRes.data.job_type,
      //   // "Job Industry": jobDetailRes.data.,
      //   "Job Description": jobDetailRes.data.job_description,
      //   // "Company Name": jobDetailRes.data.,
      //   "Outlet Name": jobDetailRes.data.location_id,
      //   "Special Instruction": jobDetailRes.data.special_instructions,
      //   "Job Start Date Time": jobDetailRes.data.job_start_date,
      //   "Job End Date Time": jobDetailRes.data.job_end_date,
      //   "Hourly Rate": jobDetailRes.data.hourly_rate,
      //   // "Total Job Salary":
      //   // "Total Working Hours": body.hor,
      //   // "Total Working Minutes":
      // });
      return {
        status: true,
        data: jobDetailRes.data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.approveJobFailed'),
      });
    }
  },
  async cancelApplicant(
    applicantId: number,
    cancelObjectId: number,
    cancelIn: 'job' | 'slot',
    reasonId: number,
  ): Promise<DataResponse<null>> {
    try {
      const cancelPath = cancelIn === 'job' ? 'rejectApplicantInJob' : 'rejectApplicantInSlot';
      const res: MessageAxiosResponse = await httpClient.post(
        `${basePath}/${cancelPath}`,
        removeNullish({
          slot_id: cancelIn === 'slot' ? cancelObjectId : null,
          app_user_id: applicantId,
          job_id: cancelIn === 'job' ? cancelObjectId : null,
          reason_id: reasonId,
        }),
      );
      const { message } = res.data;
      return {
        status: true,
        message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message || i18next.t<string>('message.cancelApplicantFailed'),
      });
    }
  },
  async completedJobsByApplicant(
    payload: Payload<
      null,
      null,
      Query & {
        applicantId?: number | string;
      }
    >,
  ): Promise<DataResponse<IJobApplicant[]>> {
    try {
      const { pagination, query, cancelToken } = payload;
      const { sort, search, applicantId } = query || {};
      const res: ListAxiosResponse<IJobApplicant> = await httpClient.get(
        `${basePath}/indexApplicantsJobHistory`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              app_user_id: applicantId,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
          cancelToken,
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getRejectedApplicantTotal(jobId?: number | string): Promise<PrimitiveResponse<number>> {
    try {
      const res: ObjectAxiosResponse<{ total_number_of_rejected: number }> = await httpClient.get(
        `${basePath}/getRejectApplicantTotal`,
        {
          params: removeNullish({
            job_id: jobId,
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.total_number_of_rejected,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getBulkUpdateJobList(
    payload: Payload<null, null, Query & Partial<JobManageFilterData>>,
  ): Promise<DataResponse<IJob[]>> {
    try {
      const { pagination, query } = payload;
      const { location, job, search, dateRange, sort, jobStatus, jobWithApplicants, jobTitle } =
        query || {};
      const [startDate, endDate] = dateRange || [];
      const {
        data: { data, message },
      }: ListAxiosResponse<IJob> = await httpClient.get(`${basePath}/list-job-updating`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            job_with_applicants: jobWithApplicants ? 1 : null,
            jod_job_status: jobStatus?.value,
            location_id: location?.value,
            job_start_date: startDate ? dayjs(startDate).format(DATE_BE) : null,
            job_end_date: startDate ? dayjs(endDate).format(DATE_BE) : null,
            multi_day_job: job?.value,
            job_title: jobTitle,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });

      return {
        status: true,
        data: data.data,
        pagination: mapPaginationState(data),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getBulkUpdateJobCount(
    query: Query &
      Partial<JobManageFilterData> & {
        wage: number;
      },
  ): Promise<
    DataResponse<{
      jobIds: number[];
      total: number;
    }>
  > {
    try {
      const { wage, search, location, dateRange, job, jobStatus, jobTitle } = query;
      const [startDate, endDate] = dateRange || [];
      const res: ObjectAxiosResponse<{
        job_ids: number[];
        total: number;
      }> = await httpClient.get(`${basePath}/summary-job-updating`, {
        params: mapQueryToParams({ search }, undefined, {
          hourly_rate: wage,
          location_id: location?.value,
          job_start_date: startDate ? dayjs(startDate).set('second', 0).format(DATE_TIME_BE) : null,
          job_end_date: startDate ? dayjs(endDate).set('second', 59).format(DATE_TIME_BE) : null,
          multi_day_job: job?.value,
          jod_job_status: jobStatus?.value,
          job_title: jobTitle,
        }),
      });

      const { job_ids, total } = res.data.data;
      return {
        status: true,
        data: {
          jobIds: job_ids,
          total,
        },
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({
        message: error.message,
        status: false,
      });
    }
  },
  async bulkUpdateJobWage(
    jobIds: number[],
    newWage: number,
    oldWage: number,
  ): Promise<DataResponse<null>> {
    try {
      const res: ObjectAxiosResponse<{
        updated_count: number;
      }> = await httpClient.patch(`${basePath}/update-job-updating`, {
        job_ids: jobIds,
        new_wage: newWage,
        old_wage: oldWage,
      });
      const { message } = res.data;
      return {
        status: true,
        message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getListCancelJobs(
    payload: Payload<
      null,
      null,
      {
        sort?: Sorter | null;
        search?: string | null;
      } & Partial<JobManageFilterData>
    >,
  ): Promise<DataResponse<IJob[]>> {
    try {
      const { pagination, query } = payload;
      const { search, sort, job, jobStatus, dateRange, location, jobWithApplicants } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<IJob> = await httpClient.get(`${basePath}/list-job-cancel`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            job_with_applicants: jobWithApplicants ? 1 : null,
            location_id: location?.value,
            jod_job_status: jobStatus?.value,
            job_start_date: startDate
              ? dayjs(startDate).set('second', 0).format(DATE_TIME_BE)
              : null,
            job_end_date: startDate ? dayjs(endDate).set('second', 59).format(DATE_TIME_BE) : null,
            multi_day_job: job?.value,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });

      const { data } = res;
      return {
        status: true,
        data: data.data.data,
        pagination: mapPaginationState(data.data),
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async cancelMultipleJobs(
    jobIds: string[],
    cancellationReasonId: number,
  ): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(`${basePath}/cancel-multiple-jobs`, {
        job_ids: jobIds,
        cancellation_reason_id: cancellationReasonId,
      });

      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.cancelUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        description: description,
        message,
      });
    }
  },
  async getBadgesOptions(): Promise<DataResponse<BadgesOption[]>> {
    try {
      const res: AxiosResponse<{ data: BadgesOption[]; message: string }> = await httpClient.get(
        `${basePath}/get-list-hm-badges`,
      );
      return {
        status: true,
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getSlotUsersList(
    payload: Payload<null, null, Query & Partial<SlotUserFilterData>>,
  ): Promise<DataResponse<ISlotUser[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, dateRange, slotId, appUserId, slotApplicantStatus } = query || {};
      const [startDate, endDate] = dateRange || [];
      const res: ListAxiosResponse<ISlotUser> = await httpClient.get(
        `${basePath}/listSlotUserForInternal`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              slot_id: slotId,
              app_user_id: appUserId,
              slot_applicant_status: slotApplicantStatus?.value,
              job_start_date: startDate ? dayjs(startDate).format(DATE_BE) : null,
              job_end_date: endDate ? dayjs(endDate).format(DATE_BE) : null,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
        },
      );
      const { data } = res;
      return {
        status: true,
        data: data.data.data,
        pagination: mapPaginationState(data.data),
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getJobSlotUserDetail(payload: {
    slotId: number | string;
    userId: number | string;
  }): Promise<DataResponse<ISlotUser>> {
    try {
      const { slotId, userId } = payload;

      const res: ObjectAxiosResponse<ISlotUser> = await httpClient.get(
        `${basePath}/detailSlotUserForInternal`,
        {
          params: {
            slot_id: slotId,
            app_user_id: userId,
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async updateSlotUser(
    slotId: number,
    userId: number,
    payload: Payload<SlotUserFormData>,
  ): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const bodyData = {
        slot_id: slotId,
        app_user_id: userId,
        ack2: body.ack2 ? 1 : 0,
        has_feed_back: body.hasFeedback ? 1 : 0,
        manager_rating_slot: Number(body.managerRating),
        manager_feedback_slot: body.managerFeedback,
        applicant_feedback_slot: body.applicantFeedback,
        applicant_rating_slot: Number(body.applicantRating),
        ack2_datetime: body.ack2Datetime ? body.ack2Datetime.format(DATE_TIME_BE) : null,
        jod_ack2_reminder: body.jodAck2Reminder ? 1 : 0,
        clock_in: body.clockIn ? body.clockIn.format(DATE_TIME_BE) : null,
        clock_out: body.clockOut ? body.clockOut.format(DATE_TIME_BE) : null,
        break_time: body.breakTime ? body.breakTime.hour() * 60 + body.breakTime.minute() : 0,
        clock_in_status: body.clockInStatus ? 1 : 0,
        clock_out_status: body.clockOutStatus ? 1 : 0,
        actual_clock_in: body.actualClockIn ? body.actualClockIn.format(DATE_TIME_BE) : null,
        actual_clock_out: body.actualClockOut ? body.actualClockOut.format(DATE_TIME_BE) : null,
        manager_clock_in: body.managerClockIn ? body.managerClockIn.format(DATE_TIME_BE) : null,
        manager_clock_out: body.managerClockOut ? body.managerClockOut.format(DATE_TIME_BE) : null,
        admin_break_time: body.adminBreakTime
          ? body.adminBreakTime.hour() * 60 + body.adminBreakTime.minute()
          : 0,
        admin_clock_in: body.adminClockIn ? body.adminClockIn.format(DATE_TIME_BE) : null,
        admin_clock_out: body.adminClockOut ? body.adminClockOut.format(DATE_TIME_BE) : null,
        sponsored_break_time: body.sponsoredBreakTime
          ? body.sponsoredBreakTime.hour() * 60 + body.sponsoredBreakTime.minute()
          : 0,
        admin_hourly_rate: body.adminHourlyRate ? Number(body.adminHourlyRate) : 0,
        admin_comment: body.adminComment,
        admin_jod_allowance: body.adminJodAllowance,
        deleted_at: body.deletedAt ? body.deletedAt.format(DATE_TIME_BE) : null,
        status: body.status.value,
        admin_jod_allowance_deduction: body.adminJodAllowanceDeduction ? 1 : 0,
      };
      const res: AxiosResponse<{ data: ISlotUser; message: string }> = await httpClient.put(
        `${basePath}/updateSlotUserForInternal`,
        removeNullish(bodyData),
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type JobApi = typeof jobApi;
